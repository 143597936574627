<template>
  <span v-if="isVerified">
    <fa
      :icon="['fas', 'circle-check']"
      class="mr-1"
    />
    {{ t('identityVerified') }}
  </span>
</template>

<script>
export default {
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n({
      useScope: 'local',
    })

    return { t }
  },
}
</script>

<i18n lang="json">
{
  "en": {
    "identityVerified": "Identity verified"
  },
  "fr": {
    "identityVerified": "Identité vérifiée"
  }
}
</i18n>
